/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {x} from '@xstyled/styled-components';
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
import landscapeDark from './images/ZenWave-360-Landscape-dark.png';
import landscapeLight from './images/ZenWave-360-Landscape-light.png';
import dddFeedbackLoop from './images/ZenWave-360-DDD-Feedback-Loop-with-ZW-Products.excalidraw.svg';
import dddFeedbackLoopDark from './images/ZenWave-360-DDD-Feedback-Loop-with-ZW-Products.dark.png';
import dddFeedbackLoopLight from './images/ZenWave-360-DDD-Feedback-Loop-with-ZW-Products.light.png';
import dddTriangleLightImage from './images/business-developers-software-triangle.light.png';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    blockquote: "blockquote",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "zenwave-360º",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwave-360%C2%BA",
    "aria-label": "zenwave 360º permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWave 360º"), "\n", React.createElement(Quote, null, React.createElement(_components.p, null, "The critical complexity of most software projects is in understanding the domain itself.\r\n", React.createElement(QuoteAuthor, null, "- Eric Evans in Domain-Driven Design"))), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "👉 ZenWave360 Helps You Create Software Easy to Understand"), "\n"), "\n", React.createElement(_components.p, null, "Being easy to understand should be the gold standard of good and maintainable software."), "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "ZenWave 360º"), " is a set of tools built on the foundations of ", React.createElement("strong", {
    className: "orange"
  }, "Domain Driven Design"), " and ", React.createElement("strong", {
    className: "orange"
  }, "API-First"), " principles for ", React.createElement("strong", {
    className: "orange"
  }, "Event-Driven Architectures.")), "\n", React.createElement(_components.h2, {
    id: "domain-driven-design-and-api-first-for-event-driven-microservices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#domain-driven-design-and-api-first-for-event-driven-microservices",
    "aria-label": "domain driven design and api first for event driven microservices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Domain Driven Design and API-First for Event Driven Microservices"), "\n", React.createElement(Quote, null, "The domain model is a powerful communication tool. It provides a shared vision of the structure and behavior of the system."), "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Event-Driven Architecture"), " reflects how we conduct business in real life - asynchronously and in response to events. That is why it is gaining popularity as the go-to architectural pattern for building enterprise applications."), "\n", React.createElement(_components.p, null, "Our lives and businesses are a continuous sequence of events. We are perpetually responding to these events and initiating new ones. ", React.createElement("strong", {
    className: "orange"
  }, "This is a reflection of how we operate in the real world"), ". Therefore, Event-Driven Architectures (EDAs) are ideally suited for creating software that accurately represents and empowers our business processes."), "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Domain-Driven Design (DDD)"), " is a software development approach that emphasizes the importance of understanding the business domain in order to create effective software. Design, in the DDD sense, ", React.createElement("strong", {
    className: "orange"
  }, "is really about understanding"), ", not inventing. Focusing on creating software that matches the mental model of the problem domain."), "\n", React.createElement(_components.p, null, "With a ", React.createElement("strong", {
    className: "orange"
  }, "good understanding of the business"), " process we want to model even the ", React.createElement("strong", {
    className: "orange"
  }, "simplest architectural pattern"), " suffices for crafting software that's straightforward and maintainable."), "\n", React.createElement(_components.p, null, "On the other hand with a ", React.createElement("strong", {
    className: "orange"
  }, "poor understanding"), " of the business process we want to model, there is ", React.createElement("strong", {
    className: "orange"
  }, "no amount of clean code"), ", clean architecture, SOLID principles, hexagonal, event-driven... that will make your code easy to maintain and to evolve because with a poor understanding, new requirements will spring as a surprise shaking the foundations of your previous design."), "\n", React.createElement(_components.h2, {
    id: "zdl-domain-model-language-as-ubiquitous-language-format",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zdl-domain-model-language-as-ubiquitous-language-format",
    "aria-label": "zdl domain model language as ubiquitous language format permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZDL Domain Model Language as 'Ubiquitous Language' format"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/docs/event-driven-design/zenwave-domain-language"
  }, "ZDL"), " is a developer friendly domain model format, that can map the discoveries of ", React.createElement(_components.a, {
    href: "/docs/event-driven-design/event-storming"
  }, "Event-Storming"), " workshops capturing the essence of Event-Driven Domains while maintaining the business language brought up during Event-Storming sessions."), "\n", React.createElement("strong", {
    className: "orange"
  }, "ZDL Domain Model Language is"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Developer Friendly"), ": compact and concise format resembling a very lightweight programing language."), "\n", React.createElement(_components.li, null, "Retaining the ", React.createElement("strong", {
    className: "orange"
  }, "Business Language"), " discovered during Event-Storming sessions"), "\n", React.createElement(_components.li, null, "And because it's also ", React.createElement("strong", {
    className: "orange"
  }, "Machine Friendly"), " it can be converted into multiple software artifacts ", React.createElement("strong", {
    className: "orange"
  }, "propagating that Common/Ubiquitous Language"), " automatically and effortless."), "\n"), "\n", React.createElement(_components.p, null, "Efectively closing the Gap between Domain Experts, Technical Experts and Working Software & Tests"), "\n", React.createElement(Image, {
    alt: "Domain Driven Design Triangle",
    src: dddTriangleLightImage,
    width: "80%"
  }), "\n", React.createElement(_components.h2, {
    id: "zenwave-sdk-accelerates-your-development-cycle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwave-sdk-accelerates-your-development-cycle",
    "aria-label": "zenwave sdk accelerates your development cycle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWave SDK Accelerates your Development Cycle"), "\n", React.createElement(Quote, null, "Code is not the center, but merely one expression of the model."), "\n", React.createElement(_components.p, null, "Because the most effective way to model is to do so in the context of a running system that can demonstrate whether the model actually works. ZenWave SDK helps you convert your Domain Models into working Software & Tests."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/docs/zenwave-sdk"
  }, "ZenWave SDK"), " accelerates your development cycle by converting your Domain Models into well crafted software artifacts you can assemble into a running application."), "\n", React.createElement(_components.p, null, "With ZenWave SDK you can quickly convert your models into working software:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "API definitions: OpenAPI and AsyncAPI v2/v3"), "\n", React.createElement(_components.li, null, "Multiple backend implementations with Spring-Boot and Spring-Cloud: with JPA and MongoDB flavors."), "\n", React.createElement(_components.li, null, "SpringMVC RestControllers from OpenAPI + ZDL: complete controller skeletons with their mapstruct mappers, connecting the inside of your backend with the external world through your APIs."), "\n", React.createElement(_components.li, null, "API-First code generator for AsyncAPI v2/v3 for producing and consuming async events."), "\n", React.createElement(_components.li, null, "API-Testing with Spring WebTestClient and KarateDSL."), "\n"), "\n", React.createElement(_components.p, null, "ZenWave SDK main purpose is to help you produce successful software projects by dramatically shortening the feedback loop between the expert domain knowledge and working software and its tests."), "\n", React.createElement(Image, {
    alt: "Domain Driven Design Feedback Loop",
    dark: dddFeedbackLoopDark,
    light: dddFeedbackLoopLight
  }), "\n", React.createElement(_components.p, null, "In this way all team members: ", React.createElement(_components.strong, null, "Domain Experts"), ", ", React.createElement(_components.strong, null, "Product Owners"), ", ", React.createElement(_components.strong, null, "Software Architects"), ", ", React.createElement(_components.strong, null, "Developers"), " and ", React.createElement(_components.strong, null, "Testers"), " can provide early feedback based on an ", React.createElement(_components.strong, null, "Ubiquitous Language (ZDL)"), " and the software and tests generated from that model."), "\n", React.createElement(_components.h2, {
    id: "zenwave-360º-landscape",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwave-360%C2%BA-landscape",
    "aria-label": "zenwave 360º landscape permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWave 360º Landscape"), "\n", React.createElement(_components.p, null, "ZenWave 360º is a set of tools that help you create software that is easy to understand, maintain and fulfill the requirements of your users."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ZDL Domain Modeling Language", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ZenWave SDK: CLI and Maven Plugin"), "\n", React.createElement(_components.li, null, "ZenWave Editor: IntelliJ Plugin"), "\n", React.createElement(_components.li, null, "ZenWave Models Catalog: <coming soon>"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, " "), "\n", React.createElement(Image, {
    alt: "ZenWave360 Landscape",
    dark: landscapeDark,
    light: landscapeLight
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
